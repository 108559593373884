/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para instalar mas plugins a nuestro Compiz Fusion desde aptitude necesitamos añadir estos repositorios al archivo /etc/apt/sources.list:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "deb http://ppa.launchpad.net/compiz/ubuntu hardy main"), "\n", React.createElement(_components.p, null, "deb-src http://ppa.launchpad.net/compiz/ubuntu hardy main"), "\n"), "\n", React.createElement(_components.p, null, "Podemos cambiar el hardy por el nombre de la versión que tengamos."), "\n", React.createElement(_components.p, null, "El paso siguiente es irnos a consola e instalar los plugins:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "$ aptitude install compiz-fusion-plugins-main compiz-fusion-plugins-extra"), "\n"), "\n", React.createElement(_components.p, null, "Y ya podemos disfrutar de nuevos plugins como por ejemplo el escritorio con forma de cilindro o esfera, los reflejos en el “suelo” y muchas cosas mas."), "\n", React.createElement(_components.p, null, "Ale a trastear."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
